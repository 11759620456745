import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { AppContext } from 'src/state/AppState'
import { typography, mq, colors } from 'src/styles'

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ mq.mediumAndBelow } {
		display: block;
	}
`
const TextContainer = styled(ScrollEntrance)`
	text-align: ${ ({ alignment }) => alignment };
	width: 100%;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
		h1, h2, h3, h4, h5, h6, p {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
`

const Eyebrow = styled.h6`
	${ typography.eyebrow }
`

const Headline = styled.h3`
	${ ({ headlineSize }) => typography[headlineSize] }
`

const Text = styled.div`
	p {
		margin-bottom: 0 ${ ({ textSize }) => textSize };
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
	blockquote p {
		font-size: inherit;
		font-weight: inline-block;
		line-height: inherit;
		letter-spacing: inherit;
		border-left: 3px solid ${ colors.hrColor };
		padding-left: .6em;
	}
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: inline-block;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 8px;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
`

const TextLockup = ({
		theme,
		eyebrow,
		headline,
		headlineSize,
		text,
		textSize = 'body',
		actions,
		className,
		icon,
		alignment,
		additions,
		entranceDelay,
		transitionIn
	}) => {
	const { toggleModal } = useContext(AppContext)
	return (
		<Wrapper className={className} alignment={alignment}>
			<div>
				<TextContainer alignment={alignment} delay={entranceDelay} transitionIn={transitionIn}>

					{(icon && icon.svgContent) && (
						<div style={{ margin: 'auto', width: 50, height: 50, marginBottom: '1em' }} dangerouslySetInnerHTML={{ __html: icon && icon.svgContent }}/>
					)}

					{eyebrow && (
						<div>
							<Eyebrow>{eyebrow}</Eyebrow>
						</div>
					)}

					{headline && (
						<Headline headlineSize={headlineSize}>{headline}</Headline>
					)}

					{text && text.raw &&
						<Text textSize={textSize} alignment={alignment}><ContentfulRichText richText={text} textSize={textSize}/></Text>
					}

					{typeof text === 'string' &&
						<Text textSize={textSize} alignment={alignment}><p>{text}</p></Text>
					}

					{text && typeof text !== 'string' && !text.raw &&
						<Text textSize={textSize} alignment={alignment}>{text}</Text>
					}

					{actions && (
						<ButtonActions buttons={actions} alignment={alignment}>
							{actions.map((action, index) => {
								let url = false
								let onClick = () => {}
								let mailLink = false
								if (!action.specialActions) {
									url = action.to || action.linkToPage.slug
									if (url.includes('mailto:')) {
										mailLink = true
									} else {
										url = '/' + url
									}
								}
								if (action.specialActions === 'Order Online') {
									onClick = () => toggleModal('restaurantList')
									url = false
									action.external = false
								}
								if (action.specialActions === 'Catering Form') {
									onClick = () => toggleModal('cateringForm')
									url = false
									action.external = false
								}

								if (action.__typename === 'ContentfulButton') {
									return (
										<ActionWrapper>
											<Button
												key={'button-' + index}
												setTheme={action.theme}
												to={url}
												onClick={onClick}
												external={action.external || action.to}
												target={action.openInNewTab || mailLink ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper>
											<TextLink
												key={'button-' + index}
												to={url}
												external={action.external || action.to}
												target={action.openInNewTab || mailLink ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</TextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: 'inherit',
	textSize: 'body',
	entranceDelay: 0,
	transitionIn: true
}

export default TextLockup
