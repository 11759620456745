import React, { useState } from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import ContentfulRichText from 'src/components/ContentfulRichText'
import NewsletterSignup from 'src/components/NewsletterSignup'
import { LogoSmall } from 'src/components/Logo'
import ThemeSelector from 'src/components/ThemeSelector'
import { mq, util, typography, colors, animations } from 'src/styles'
import { MdMail } from 'react-icons/md'
import { FaInstagram, FaFacebook, FaTwitter, FaPinterest } from 'react-icons/fa'

const Wrapper = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
	ul {
		list-style: none;
		a {
			position: relative;
			${ typography.bodySmall }
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 1px;
				background: ${ colors.offWhite };
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform ${ animations.mediumSpeed } ease-in-out;
			}
			&:hover {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
		}
	}
`

const FooterLogo = styled(LogoSmall)`
	${ util.responsiveStyles('width', 100, 80, 70, 60) }
`

const Pattern = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-position: center;
	background-repeat: repeat-x;
	background-size: 14px auto;
	opacity: .5;
`

const FooterMain = styled.div`
	position: relative;
	z-index: 2;
	${ util.responsiveStyles('padding-top', 150, 102, 66, 52) }
	${ util.responsiveStyles('padding-bottom', 150, 102, 66, 52) }
	
`

const FooterBottom = styled.div`
	position: relative;
	z-index: 2;
	hr {
		margin: 0 0 12px 0;
		border-color: currentcolor;
	}
	${ util.responsiveStyles('padding-bottom', 50, 40, 30, 26) }
	${ mq.smallAndBelow } {
		padding-bottom: calc(${ 100 / 7 }vw + 50px);
	}
`

const Copyright = styled.div`
	display: flex;
	align-items: center;
	${ mq.smallAndBelow } {
		display: none;
	}
	p {
		${ typography.h6 }
		${ util.responsiveStyles('font-size', 12, 12, 11, 11) }
		margin: 0;
		max-width: none;
		.mobile-hide {
			${ mq.mediumAndBelow } {
				display: none;
			}
		}
	}
`

const SmallLinks = styled.div`
	text-align: right;
	display: flex;
	justify-content: flex-start;
	${ mq.mediumAndUp } {
		justify-content: flex-end;
	}
	p {
		&:not(.last) {
			margin-right: 2em;
		}
		${ typography.h6 }
		${ util.responsiveStyles('font-size', 12, 12, 11, 11) }
		margin: 0;
		max-width: none;
		a {
			border-color: transparent;
			&:hover {
				border-color: ${ colors.lightGrey };
			}
		}
	}
`

const ListItemIcon = styled.div`
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
	svg {
		margin-top: -2px;
		display: block;
		* {
			fill: currentcolor;
		}
	}
`

const DirectionButton = styled(Button)`
	&:hover {
		background: ${ colors.offWhite };
		border-color: ${ colors.offWhite };
		color: ${ colors.darkRed };
	}
`

const Footer = ({
	title,
	socialLinks,
	footerLinks,
	smallFooterLinks,
	streetAddress,
	cityStateZip,
	directionsLink,
	phoneNumber,
	hours
}) => {
	const renderIcon = to => {
		if (to.includes('mailto')) {
			return <ListItemIcon><MdMail /></ListItemIcon>
		} else if (to.includes('instagram')) {
			return <ListItemIcon><FaInstagram /></ListItemIcon>
		} else if (to.includes('facebook')) {
			return <ListItemIcon><FaFacebook /></ListItemIcon>
		} else if (to.includes('twitter')) {
			return <ListItemIcon><FaTwitter /></ListItemIcon>
		} else if (to.includes('pinterest')) {
			return <ListItemIcon><FaPinterest /></ListItemIcon>
		}
		return false
	}

	return (
		<Wrapper setTheme="darkRed">
			<Pattern/>
			<FooterMain>
				<Grid
					small="1 [12] 1"
					medium="1 [2] [10] 1"
					extraLarge="1 [4] [8] 1"
					vAlign='top'
					rowGap='60px'
				>
					<div>
						<FooterLogo />
					</div>
					<div>
						<Grid small="[1]" larger="[1] [2]" rowGap='60px' vAlign='top' gridDirection='rtl'>
							<div>
								<p className='h6'>Stay Updated</p>
								<NewsletterSignup name='footerNewsletterSignup' />
							</div>

							<Grid small='[11] [9]' larger='[2] [2] [3]' vAlign='top' rowGap='60px'>
								<div>
									<p className='h6'>Follow Us</p>
									<ul>
										{socialLinks.map((link, index) => (
											<li key={link.id}>
												<a href={link.to} target={link.openInNewTab ? '_blank' : ''}>
													{renderIcon(link.to)}
													{link.label}
												</a>
											</li>
										))}
									</ul>
								</div>
								<div>
									<p className='h6'>The Hugh</p>
									<ul>
										{footerLinks.map((link, index) => {
											let linkTo = '/'
											if (link?.linkToPage?.slug) {
												linkTo = link?.linkToPage?.slug !== '/' ? ('/' + link?.linkToPage?.slug) : '/'
											}
											if (link.to) {
												linkTo = link.to
											}
											return (
												<li key={link.id}>
													<Link
														to={linkTo}
														key={linkTo + '-footer-link'}
														external={link.to}
														target={link.to && '_blank'}
													>
														{link.label}
													</Link>
												</li>
											)
										})}
									</ul>
								</div>
								<div>
									<p className='h6'>Visit Us</p>
									<p class="small">{streetAddress}<br/>{cityStateZip}</p>
									{directionsLink && (
										<DirectionButton
											icon='arrow_forward'
											iconPosition='right'
											size='small'
											to={directionsLink}
											target='_blank'
											external
											setTheme='secondary'
										>Directions</DirectionButton>
									)}
									{phoneNumber && (
										<p class="small" style={{ margin: '1.6em 0 0 0' }}>P <a href={'tel:' + phoneNumber}>{phoneNumber}</a></p>
									)}
									{hours?.raw && (
										<div style={{ margin: '1.6em 0 0 0' }}>
											<ContentfulRichText richText={hours} textSize="bodySmall" />
										</div>
									)}
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</FooterMain>
			<FooterBottom>
				<Grid
					small="1 [12] 1"
					// large="1 [24] 1"
					vAlign="center"
				>
					<div><hr/></div>
				</Grid>
				<Grid
					// small="1 [2] [10] 1"
					small="1 [12] 1"
					medium="1 [8] [4] 1"
					vAlign="center"
					gridDirection='rtl'
				>
					<SmallLinks>
						<p className="sm"><Link to="https://hyperlinknyc.com/" target="_blank" external>Site Credit</Link></p>
						{smallFooterLinks.map((link, index) => {
							if (!link.value || !link.key) {
								return null
							}
							return <p className="sm last" key={'smallLink-' + index}><Link to={link.value} target="_blank" external={link.value.includes('http')}>{link.key}</Link></p>
						})}
					</SmallLinks>
					<Copyright>
						<p className="sm">© <span className="mobile-hide">{title}</span> {new Date().getFullYear()}</p>
					</Copyright>
				</Grid>
			</FooterBottom>
		</Wrapper>
	)
}

export default Footer
