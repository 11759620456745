import React from 'react'
import styled from '@emotion/styled'
import LogoSvg from 'src/assets/images/logo.svg'
import LogoSolidSvg from 'src/assets/images/logo-solid.svg'
import LogoSmallSvg from 'src/assets/images/logo-small.svg'
import LogoVerticalSvg from 'src/assets/images/logo-vertical.svg'
import { animations } from 'src/styles'

const LogoWrapper = styled.div`
	height: auto;
	display: inlin-block;
	vertical-align: top;
	svg {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
		color: inherit;
		* {
			fill: currentcolor;
		}
	}
`

const StyledLogoSvg = styled(LogoSvg)``
const StyledLogoSolidSvg = styled(LogoSolidSvg)``
const StyledLogoVerticalSvg = styled(LogoVerticalSvg)``

const TransitionLogoWrapper = styled.div`
	height: auto;
	display: inlin-block;
	vertical-align: top;
	position: relative;
	svg {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		height: auto;
		color: inherit;
		transition: opacity ${ animations.mediumSpeed } ease-in-out;
		* {
			fill: currentcolor;
		}
	}
	${ StyledLogoVerticalSvg } {
		width: 45%;
	}
	${ StyledLogoSvg },
	${ StyledLogoVerticalSvg } {
		position: absolute;
		top: 0;
		left: 0;
		${ ({ logoType }) => !logoType || logoType === 'default' ? `
			opacity: 1;
		` : `
			opacity: 0;
		` }
	}
	${ StyledLogoSolidSvg } {
		${ ({ logoType }) => logoType === 'solid' ? `
			opacity: 1;
		` : `
			opacity: 0;
		` }
	}
`

const Logo = ({ className }) => (
	<LogoWrapper className={className}>
		<LogoSvg />
	</LogoWrapper>
)

export const TransitionLogo = ({ className, type, logoType }) => (
	<TransitionLogoWrapper className={className} logoType={type}>
		{logoType === 'vertical' ? (
			<StyledLogoVerticalSvg />
		) : (
			<StyledLogoSvg />
		)}
		<StyledLogoSolidSvg />
	</TransitionLogoWrapper>
)

export const LogoSolid = ({ className }) => (
	<LogoWrapper className={className}>
		<LogoSolidSvg />
	</LogoWrapper>
)

export const Logomark = ({ className }) => (
	<LogoWrapper className={className}>
		<LogoSvg />
	</LogoWrapper>
)

export const LogoSmall = ({ className }) => (
	<LogoWrapper className={className}>
		<LogoSmallSvg />
	</LogoWrapper>
)

export default Logo
