import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { typography, colors } from 'src/styles'
import Video from 'src/components/Video'
import MaterialIcon from 'src/components/MaterialIcon'

const RichTextWrapper = styled.div`
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
	p {
		min-height: 1em;
		${ ({ textSize }) => textSize ? typography[textSize] : `` }
	}
	> * {
		display: block;
		&:first-child {
		  margin-top: 0;
		}
		&:last-child {
		  margin-bottom: 0;
		}
	}
	ul, ol {
		margin: 1.5em 0;
	}
`

const StyledUL = styled.ul`
	${ ({ type }) => type !== 'default' ? `
		padding-left: 0;
	` : `` }
`

const StyledListItem = styled.li`
	${ ({ type }) => type !== 'default' ? `
		display: flex;
		::marker {
			display: none;
			content: '';
		}
	` : `` }
`

const ItemIcon = styled(MaterialIcon)`
	display: block;
	font-size: 1.2em;
	margin-top: .1em;
	margin-right: .3em;
	margin-left: -.1em;
	flex-grow: 0;
	flex-shrink: 0;
	color: ${ colors.mainColor };
`

const InlineMedia = styled.div`
	display: block;
	${ ({ first }) => first ? `
		margin-top: 0;
	` : `
		margin-top: 24px;
	` }
	${ ({ last }) => last ? `
		margin-bottom: 0;
	` : `
		margin-bottom: 24px;
	` }
`

const renderMedia = (file, image) => {
	console.log(file)
	console.log(image)
	if (file?.contentType?.includes('video')) {
		return (
			<InlineMedia as={Video} src={file.url} />
		)
	} else if (image) {
		return (
			<InlineMedia as={GatsbyImage} image={image} alt={file.fileName} format={['auto', 'avif', 'webp']} />
		)
	} else {
		return false
	}
}

const options = (richText, linkInNewTab, listType) => {
	const textOptions = {
		renderMark: {
			[MARKS.BOLD]: text => <b>{text}</b>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p>{children}</p>
			),
			[BLOCKS.HEADING_1]: (node, children) => (
				<h1>{children}</h1>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<h2>{children}</h2>
			),
			[BLOCKS.HEADING_3]: (node, children) => (
				<h3>{children}</h3>
			),
			[BLOCKS.HEADING_4]: (node, children) => (
				<h4>{children}</h4>
			),
			[BLOCKS.HEADING_5]: (node, children) => (
				<h5>{children}</h5>
			),
			[BLOCKS.HEADING_6]: (node, children) => (
				<h6>{children}</h6>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<StyledUL type={listType}>
					{children.map((item, index) => (
						<StyledListItem type={listType}>
							{listType === 'checklist' && (<ItemIcon>check</ItemIcon>)}
							{item.props.children}
						</StyledListItem>
					))}
				</StyledUL>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
				<ol>{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li>{children}</li>
			),
			[BLOCKS.QUOTE]: (node, children) => (
				<blockquote>{children}</blockquote>
			),
			[BLOCKS.HR]: (node, children) => (
				<hr/>
			),
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
				<div>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: node => {
				const file = node.data.target.file
				const image = node.data.target.gatsbyImageData
				return (
					<Fragment>
	          {renderMedia(file, image)}
	        </Fragment>
				)
			},
			[INLINES.HYPERLINK]: (node, children) => (
				<a href={node.data.uri} target={linkInNewTab ? '_blank' : ''} rel={linkInNewTab ? 'noopener noreferrer' : ''}>{children}</a>
			)
		},
	}
	return textOptions
}

const ContentfulRichText = ({ richText, className, textSize, listType, linkInNewTab = false }) => {
	if (!richText || !richText.raw) {
		return false
	}

	let rawObj = JSON.parse(richText.raw)

	rawObj.content[0].firstItem = true
	rawObj.content[rawObj.content.length - 1].lastItem = true

	rawObj = JSON.stringify(rawObj)

	return (
		<RichTextWrapper className={className} textSize={textSize}>
			{renderRichText(richText, options(richText, linkInNewTab, listType))}
		</RichTextWrapper>
	)
}

ContentfulRichText.defaultProps = {
	textSize: 'body',
	listType: 'default'
}

export default ContentfulRichText
