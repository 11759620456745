import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'

const Wrapper = styled(Section)`
  ${ ({ alignment, textAlignment }) => alignment !== 'right' ? `
    text-align: ${ alignment };
    ${ alignment === 'center' && textAlignment === 'center' ? `
      h1, h2, h3, h4, h5, h6, p {
        margin-left: auto;
        margin-right: auto;
      }
    ` : `` }
    h1, h2, h3 {
      max-width: 24em;
    }
    h4, h5, h6 {
      max-width: 24em;
    }
    p {
      max-width: 36em;
    }
  ` : `` }
`

const TextSection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  eyebrow,
  headline,
  headlineSize,
  text,
  paragraphSize,
  actions,
  alignment,
  isFirstSection,
  textAlignment,
  id
}) => {
  if (!textAlignment) {
    textAlignment = alignment
  }
  if (!text && !headline) {
    return false
  }
  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: '1 [9] 4',
      large: '1 [9] 4',
      larger: '1 [9] 4'
    },
    center: {
      medium: '2 [10] 2',
      larger: '3 [8] 3',
      extraLarge: '4 [6] 4'
    },
    right: {
      medium: '7 [6] 1',
      large: '7 [6] 1',
      larger: '7 [5] 2'
    }
  }

  if (!alignment || alignment === null) {
    alignment = 'left'
  }

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      textAlignment={textAlignment}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        medium={align[alignment].medium}
        large={align[alignment].large}
        larger={align[alignment].larger}
        extraLarge={align[alignment].extraLarge}
      >
        <TextLockup
          headline={headline}
          eyebrow={eyebrow}
          text={text}
          textSize={paragraphSize}
          actions={actions}
          alignment={textAlignment || alignment}
          headlineSize={headlineSize}
        />
      </Grid>
    </Wrapper>
  )
}

export default TextSection
