import React, { Fragment } from 'react'
import withSizes from 'react-sizes'
import { mediaQueries } from 'src/styles'

const widthToRenderer = (winWidth, small, medium, large, larger, custom) => {
	if (custom?.breakpoint && winWidth >= custom?.breakpoint) {
		return custom.content
	}

	if (larger && winWidth > mediaQueries.largerBreakpoint) {
		return larger
	}

	if (large && winWidth > mediaQueries.mediumBreakpoint) {
		return large
	}

	if (medium && winWidth > mediaQueries.smallBreakpoint) {
		return medium
	}

	return small || medium || large || larger
}

const ResponsiveComponent = ({ winWidth, small, medium, large, larger, custom }) => (
	<Fragment>
		{widthToRenderer(
			winWidth,
			small,
			medium,
			large,
			larger,
			custom
		)}
	</Fragment>
)

const sizesToProps = ({ width, height }) => ({
	winWidth: width
})

export default withSizes(sizesToProps)(ResponsiveComponent)
