import React, { useState } from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import ThemeSelector from 'src/components/ThemeSelector'
import Button from 'src/components/Button'
import { colors, typography, util, animations, mq } from 'src/styles'
import { Transition } from 'react-transition-group'
import AnimateHeight from 'react-animate-height'
import BalanceText from 'react-balance-text'
import { GatsbyImage } from "gatsby-plugin-image";

const timeout = 500
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	z-index: 7;
`

const RestaurantItemHeader = styled.div`
	${ ({ size }) => size ? `${ typography[size] }` : typography.h2 }
	${ ({ size }) => size === 'smallCaps' ? `
		margin-top: 20px;
	` : `` }
	display: block;
	padding: 2px 0;
	display: flex;
	align-items: flex-start;
	${ util.responsiveStyles('padding-left', 32, 30, 26, 20) }
	${ util.responsiveStyles('padding-right', 32, 30, 26, 20) }
	${ util.responsiveStyles('padding-top', 25, 25, 21, 13) }
	${ util.responsiveStyles('padding-bottom', 25, 25, 21, 13) }
	button {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: -11px;
	}
`

const HeaderInfo = styled.div`
	flex-grow: 1;
	cursor: pointer;
	h6 {
		margin: 0 0 .3em 0;
	}
	p {
		margin: 0;
		${ typography.bodySmall }
	}
`

const ItemContent = styled.div`
	padding-top: 1px;
	display: flex;
	padding-top: 12px;
`

const PanelHeader = styled.div`
	${ util.responsiveStyles('padding-left', 32, 30, 26, 20) }
	${ util.responsiveStyles('padding-right', 32, 30, 26, 20) }
	${ util.responsiveStyles('padding-top', 26, 26, 26, 18) }
	${ util.responsiveStyles('padding-bottom', 26, 26, 26, 18) }
	flex-grow: 0;
	width: 100%;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${ rgba(colors.bgColor, 0.2) };
	h3 {
		margin: 0;
		// ${ typography.h5 }
	}
	button {
		margin-right: -13px;
	}
`

const PanelContent = styled.div`
	flex-grow: 1;
	width: 100%;
	flex-shrink: 0;
	-webkit-overflow-scrolling: touch;
	overflow: auto;
	height: 1px;
`

const Panel = styled(ThemeSelector)`
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	${ util.responsiveStyles('max-width', 700, 600, 550, 500) }
	transition: transform ${ timeout }ms ${ timingFunction };
	transform: translate3d(110%, 0, 0);
	box-shadow: -1px 0 0 ${ rgba(colors.bgColor, 0.1) };
	${ mq.smallAndBelow } {
		box-shadow: none;
		transform: translate3d(0, 101%, 0);
		${ util.responsiveStyles('max-width', 1000, 1000, 1000, 1000) }
	}
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		transform: none;
		${ mq.smallAndBelow } {
			transform: none;
		}
	` : `` }
`

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	background: ${ colors.charcoal };
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	transition: opacity ${ timeout }ms ease-in-out;
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		opacity: 0;
	` : `` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		opacity: .5;
	` : `` }
`

const RestaurantItem = styled.div`
	${ ({ currentItem }) => currentItem ? `
		background: ${ colors.black };
	` : `` }
	${ ({ lastItem }) => !lastItem ? `
		border-bottom: 1px solid ${ rgba(colors.bgColor, 0.2) };
	` : `` }
	transition: background ${ animations.mediumSpeed } ease-in-out;
	&:hover {
		background: ${ colors.black };
	}
`

const OrderLink = styled(Link)`
	display: block;
	flex-shrink: 0;
	flex-grow: 0;
	${ typography.h6 }
	background: rgba(255, 255, 255, .2);
	line-height: 1em;
	padding: .7em;
	margin: 0 4px 4px 0;
	&:hover {
		background: ${ colors.mainColor };
	}
`

const HeaderImage = styled(GatsbyImage)`
	flex-shrink: 0;
	${ util.responsiveStyles('width', 120, 100, 80, 75) }
	${ util.responsiveStyles('margin-right', 18, 16, 14, 14) }
`

const RestaurantsPanel = ({
	open,
	toggleRestaurantsList,
	restaurants = [],
}) => {
	const [ expandedItem, setExpandedItem ] = useState('')
	const toggleItem = id => {
		if (expandedItem === id) {
			setExpandedItem('')
		} else {
			setExpandedItem(id)
		}
	}

	const closeList = () => {
		toggleRestaurantsList(false)
		setExpandedItem('')
	}

  return (
    <Transition
      in={open}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
      mountOnEnter
    >
      {transitionStatus => (
        <Wrapper>
        	<Overlay transitionStatus={transitionStatus} onClick={() => closeList()}/>
          <Panel transitionStatus={transitionStatus} setTheme='charcoal'>
          	<PanelHeader>
          		<h3>Order Online</h3>
          		<Button shape='circle' size='small' setTheme='transparentLight' icon='close' title='Close restaurant list' name='Close restaurant list' onClick={() => closeList()}/>
          	</PanelHeader>
          	<PanelContent>
	            {restaurants.map((restaurant, index) => {
	            	restaurant = restaurant.node
	            	{/*restaurant.thumbnailTiny.gatsbyImageData.aspectRatio = 1
	            	restaurant.thumbnailTiny.gatsbyImageData.width = 1
	            	restaurant.thumbnailTiny.gatsbyImageData.height = 1*/}
	            	return (
		              <RestaurantItem lastItem={restaurants.length - 1 === index} currentItem={expandedItem === restaurant.slug}>
		                <RestaurantItemHeader>
		                	<HeaderImage image={restaurant.thumbnailTiny.gatsbyImageData}/>
		                	<HeaderInfo>
		                  	<h6>{restaurant.title}</h6>
		                  	<p>{restaurant.shortDescription}</p>
		                  	<div>
													<ItemContent>
														{restaurant.orderOnlineLinks && restaurant.orderOnlineLinks.map(link => (
															<OrderLink to={link.value} external={true} target='_blank'>{link.key}</OrderLink>
														))}
													</ItemContent>
												</div>
		                	</HeaderInfo>
		                </RestaurantItemHeader>
		              </RestaurantItem>
		            )
		          })}
            </PanelContent>
          </Panel>
        </Wrapper>
      )}
    </Transition>
  )
}

export default RestaurantsPanel
