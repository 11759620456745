import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import Grid from 'src/components/Grid'
import ThemeSelector from 'src/components/ThemeSelector'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { colors, typography, util, mq } from 'src/styles'
import { Transition } from 'react-transition-group'
import { AppContext } from 'src/state/AppState'

const timeout = 300
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled(ThemeSelector)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	${ util.responsiveStyles('padding-top', 113, 105, 90, 50) }
	transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
	// transform: translate3d(110%, 0, 0);
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		// transform: translate3d(110%, 0, 0);
		opacity: 0;
	` : `` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		// transform: none;
		opacity: 1;
	` : `` }
`

const MobileNavLink = styled(Link)`
	${ ({ size }) => size ? `${ typography[size] }` : typography.h1 }
	line-height: 1.2em;
	${ ({ size }) => size === 'smallCaps' ? `
		margin-top: 20px;
	` : `` }
	display: block;
	padding: 2px 0;
	color: inherit;
	span {
		display: inline-block;
	}
`

const MainSection = styled.div`
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	padding: 5vw 0 0;
`

const BottomSection = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	padding: 4vw 0 6vw;
	color: inherit;
	p a, a {
		border-color: transparent;
		${ typography.h6 }
	}
	b {
		color: inherit;
	}
`

const SubLinks = styled.ul`
	margin: 7px 0 30px;
	padding-left: 0;
	list-style: none;
	li {
		padding: 0;
		a {
			display: block;
			padding: .15em 0 .15em 0;
			${ typography.bodyLarge }
		}
		&:first-of-type {
			a {
				padding-top: 0;
			}
		}
		&:last-of-type {
			a {
				padding-bottom: 0;
			}
		}
	}
`

const OrderButton = styled(Button)`
	width: 100%;
	margin-bottom: 20px;
`

const MobileMenu = ({ open,
	toggleMobileMenu,
	navLinks = [],
	socialLinks = [],
	toggleRestaurantsList,
	site
}) => {
	const { toggleModal } = useContext(AppContext)
  return (
    <Transition
      in={open}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
      mountOnEnter
    >
      {transitionStatus => (
        <Wrapper transitionStatus={transitionStatus} setTheme='charcoal'>
          <MainSection>
            <Grid small="1 [12] 1">
              <ScrollEntrance>
								<OrderButton onClick={() => toggleModal('restaurantList')} setTheme="lightGrey" size='large'>Order Online</OrderButton>
                {navLinks.map((link, index) => {
                	let linkTo = '/'
                  if (link.to) {
                    linkTo = link?.to?.slug !== '/' ? ('/' + link?.to?.slug) : '/'
                  }
                  if (link.externalLink) {
                    linkTo = link.externalLink
                  }
                	return (
	                  <div>
	                    <MobileNavLink
	                    	to={linkTo}
	                    	external={link.externalLink}
                        target={link.externalLink && '_blank'}
	                    >
	                    	<span onClick={toggleMobileMenu}>{link.label}</span>
	                    </MobileNavLink>
	                    {link.dropdownLinks && (
	                      <SubLinks>
	                        {link.dropdownLinks.map((dropdownLink, index) => {
	                        	let dropdownLinkTo = '/'
	                          if (dropdownLink.to) {
	                            dropdownLinkTo = dropdownLink?.to?.slug !== '/' ? ('/' + dropdownLink?.to?.slug) : '/'
	                          }
	                          if (dropdownLink.externalLink) {
	                            dropdownLinkTo = dropdownLink.externalLink
	                          }
	                        	return (
		                          <li>
		                            <Link
	                                to={dropdownLinkTo}
	                                external={dropdownLink.externalLink}
	                                target={dropdownLink.externalLink && '_blank'}
	                              >{dropdownLink.label}</Link>
		                          </li>
		                        )
	                      	})}
	                      </SubLinks>
	                    )}
	                  </div>
	                )
              	})}
								{site.directionsLink && (
									<div>
										<MobileNavLink
											to={site.directionsLink}
											external={true}
											target={'_blank'}
											title={'Directions to The Hugh'}
										>
											Directions
										</MobileNavLink>
									</div>
								)}
              </ScrollEntrance>
            </Grid>
          </MainSection>
          <BottomSection>
            <Grid small="1 [12] 1" rowGap="3vw">
							<div>
								{socialLinks.map((link, index) => (
                  <div>
                    <Link to={'/' + link.to.slug}><span onClick={toggleMobileMenu}>{link.label}</span></Link>
                  </div>
                ))}
							</div>
            </Grid>
          </BottomSection>
        </Wrapper>
      )}
    </Transition>
  )
}

export default MobileMenu
