import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import { colors, typography, animations, mq } from 'src/styles'
import { validateEmail } from 'src/utils/validations'

const Wrapper = styled.div`
	border-radius: 2px;
`

const NewsletterButton = styled(Button)`
	background: transparent;
	color: currentcolor;
	border-color: currentcolor;
	border-width: 2px;
	border-left: none;
	${ ({ disabled, setTheme }) => !disabled ? `
		&:hover {
			${ setTheme !== 'default' ? `
				background: ${ colors.offWhite };
				border-color: ${ colors.offWhite };
			` : `
				background: ${ colors.red };
				border-color: ${ colors.textColor };
			` }
		}
	` : '' }
	&:disabled,
	&[disabled] {
		opacity: 1;
		> div {
			opacity: .4;
		}
	}
`

const Flodesk = styled.div`
	text-align: inherit;
	[data-ff-el="root"].ff-${ ({ formId }) => formId},
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__container {
		color: inherit !important;
		background: transparent !important;
	}
	form {
		padding: 0 !important;
		background: transparent !important;
	}
	.ff-${ ({ formId }) => formId}__title,
	.ff-${ ({ formId }) => formId}__subtitle {
		display: none !important;
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__form {
		color: inherit !important;
		text-align: inherit;
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__content {
		margin: 0;
		width: 100%;
		${ mq.mediumAndBelow } {
			display: flex !important;
		}
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__fields {
		flex-grow: 1;
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__field {
		margin: 0;
		color: inherit;
		max-width: 100%;
		input {
			height: 50px;
			${ typography.h6 }
			border: 2px solid currentcolor;
			border-right: none;
			color: inherit !important;
			background: transparent !important;
			+ label {
				top: 0;
				color: inherit !important;
				background: transparent !important;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				left: 1px;
				${ typography.h6 }
			}
		}
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .ff-${ ({ formId }) => formId}__footer {
		margin: 0;
		color: inherit !important;
		background: transparent !important;
		button {
			height: 50px;
			${ typography.buttonStyle }
			transition: background ${ animations.mediumSpeed } ease-in-out,
									color ${ animations.mediumSpeed } ease-in-out;
			${ ({ theme }) => theme === 'lightGrey' ? `
				background: ${ colors.lightGrey };
				border-color: ${ colors.lightGrey };
				color: ${ colors.darkRed };
				&:hover {
					background: ${ colors.white };
					border-color: ${ colors.white };
					color: ${ colors.red };
				}
			` : `` }
		}
	}
	[data-ff-el="root"].ff-${ ({ formId }) => formId} .fd-has-success .fd-form-success > * {
		${ typography.h5 }
		text-align: inherit;
	}
`

const NewsletterSignup = ({ theme = 'lightGrey', name = 'email', formId = '63c9812ade520bd60dc33109' }) => {
	const newsletterUrl = 'https://thehughnyc.us5.list-manage.com/subscribe/post?u=19caeef8c4a32f5818b6b264b&amp;id=6eeae63759'
	const [newsletterEmail, setNewsletterEmail] = useState('')
	const [ready, setReady] = useState(false)
	
	useEffect(() => {
		setTimeout(() => {
			setReady(true)
		}, 100)
	}, [])

	if (ready) {
		if (window.fd) {
			console.log('fd!@')
			window.fd('form', {
				formId: formId,
				containerEl: '#fd-form-' + formId
			})
		}
	}
	
	return (
		<Wrapper>
			<Flodesk id={"fd-form-" + formId} formId={formId} theme={theme}/>
		</Wrapper>
	)
}

export default NewsletterSignup
