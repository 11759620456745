import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import ThemeSelector from 'src/components/ThemeSelector'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography } from 'src/styles'
import { themes } from 'src/styles/themes'
import { MdClose } from 'react-icons/md'
import AnimateHeight from 'react-animate-height'

const NotificationCollapse = styled(AnimateHeight)`
	position: relative;
`

const Wrapper = styled(ThemeSelector)`
	min-height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin: 0;
		text-align: center;
		${ typography.bodySmall }
		line-height: 1.25em;
		font-weight: ${ typography.medium };
		padding: .5em 40px;
	}
`

const CloseButton = styled(Button)`
	position: absolute;
	top: 50%;
	right: 15px;
	margin-right: -11px;
	transform: translateY(-50%);
	color: inherit;
	background: transparent;
	border-color: transparent;
	&:hover {
		color: inherit;
		border-color: transparent;
		background: transparent;
		color: ${ ({ color }) => color };
	}
`

const NotificationBanner = ({ className, setTheme, text, closeBanner, collapsed }) => {
	if (!text) {
		return false
	}

	let bannerColor = setTheme
	if (setTheme === null) {
		bannerColor = 'darkRed'
	}

	return (
		<NotificationCollapse
			duration={300}
			delay={0}
			animateOpacity={false}
			height={collapsed ? 0 : 'auto'}
		>
			<Wrapper className={className} setTheme={'darkRed'}>
				{text.raw ? (
					<ContentfulRichText richText={text} textSize='bodySmall' />
				) : <p>{text}</p> }
				<CloseButton title='Close announcement banner' color={themes[bannerColor].hoverColor} onClick={closeBanner} icon={<MdClose size="18"/>} shape="circle" size="tiny"/>
			</Wrapper>
	  </NotificationCollapse>
	)
}

NotificationBanner.defaultProps = {
	setTheme: 'mainColor',
	collapsed: false
}

export default NotificationBanner
